<template>
  <UDropdown
    v-if="displayedItems"
    :items="displayedItems"
    class="print:hidden"
  >
    <slot />
    <template #item="{ item }">
      <UIcon
        v-if="item.icon"
        :name="item.icon"
        class="h-auto w-4 shrink-0 dark:text-white"
        :class="`text-${item.iconColor ? item.iconColor : item.color ?? color}`"
      />
      <span :class="[`text-${item.color ?? color}`, item.class]">{{ item.label }}</span>
      <UIcon
        v-if="item.trailingIcon"
        :name="item.trailingIcon"
        class="ms-auto size-4 shrink-0 dark:text-white"
        :class="`text-${item.trailingIconColor ? item.trailingIconColor : color}`"
      />
    </template>

    <template #user>
      <UserAvatar
        :name="sessionData?.user?.name || undefined"
        :image="sessionData?.user?.image || undefined"
        :email="sessionData?.user?.email || undefined"
        show-name
      />
    </template>

    <template #language>
      <SidebarLanguageSelector />
    </template>

    <template #version>
      <SidebarVersionNumber class="m-0 flex w-full justify-start p-0" />
    </template>
  </UDropdown>
</template>

<script setup lang="ts">
import type { HubDropdownItem } from '~/types/component'

const { data: sessionData } = useAuth()

const props = withDefaults(
  defineProps<{
    items: Array<Array<HubDropdownItem>>
    color?: string
  }>(),
  {
    color: 'inherit'
  }
)

const displayedItems = computed(() => {
  return props.items.map(group => {
    return group.filter(item => {
      if (item.hidden) {
        return false
      }
      return true
    })
  }).filter(group => group.length > 0)
})
</script>
